<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <div class="topSection text-center text-md-left">
          <p class="title">{{ translateCustomText("SELECT BRANDS") }}</p>
          <p class="description">
            {{
              translateCustomText(
                "Pick your brands. You will be able to select products in the next step."
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="scrollable-tbody"
      style="height: 411px; overflow-y: auto; overflow-x: hidden">
      <div class="row mx-1 pl-1 py-2">
        <div
          v-for="brand in getBrands"
          :key="brand.id"
          class="col-6 col-md-3"
          style="cursor: pointer"
          @click="checkSelectedImage(brand)">
          <LazyImage
            :src="brand.image"
            :class="[
              selectedBrandsIds.includes(brand.id) ? 'selected-image' : '', 'w-100'
            ]"
            class="commonBtn manageBtn bg-white imgEditImage"
             />
        </div>
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-between">
      <ElthButton @click="backPage()" variant="boardered">
        {{ translateCustomText("Back") }}
      </ElthButton>
      <ElthButton :isLoading="isLoadingSave" @click="nextPage()" style="border: 1px solid #503A3C; background: linear-gradient(180deg, #FFDAD8 0%, #FFA282 99%); color:#503A3C">
        {{ translateCustomText("Next") }}
      </ElthButton>
      <ElthButton style="visibility: hidden" class="d-none d-md-block">
        save
      </ElthButton>
    </div>
  </div>
</template>
<script>
import ElthButton from "./ElthButton.vue";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import axios from "axios";
export default {
  props: {
    brands: {
      type: Array,
    },
    selectedBrands: Array,
    planLimit: {
      type: Number,
      default: 2,
    },
    selected_image: {
      type: String,
      default: "",
    },
    brandQuestionId: Number,
    quizId: Number,
    apiToken: String,
  },
  mixins: [TranslationMixin],
  components: { ElthButton },
  data() {
    return {
      selectedOption: {},
      uploadImageFile: null,
      isLoadingSave: false,
      selectedBrandsIds: [],
      alreadySelected: this.selectedBrands,
    };
  },
  computed: {
    getBrands() {
      return this.brands;
    },
  },
  methods: {
    checkSelectedImage(value) {
      const alreadyExistBrand = this.alreadySelected.some(
        (brand) => brand.id == value.id
      );
      const ValueIndex = this.selectedBrandsIds.findIndex(
        (option) => option == value.id
      );
     
      if (ValueIndex > -1) {
        // this.selectedBrandsIds.splice(ValueIndex, 1);
        if (alreadyExistBrand) {
          this.toast("Unable to unselect the already chosen brand.");
        } else {
          this.selectedBrandsIds.splice(ValueIndex, 1);
        }
      } else {
             if(this.planLimit>0 && !alreadyExistBrand &&  this.selectedBrandsIds.length>=this.planLimit)
         {
               this.toast('Update your plan for more selection.')
               return
         }
        this.selectedBrandsIds.push(value.id);
      }
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
    backPage() {
      this.$emit("moveTo", 1);
    },
    async nextPage() {
      this.isLoadingSave = true;
      if (this.selectedBrandsIds.length > 0) {
        const optionIDs = this.selectedBrandsIds;
        const data = {
          questionId: this.brandQuestionId,
          email:
            localStorage.getItem("already_record_email") ||
            this.$route.query.email,
          optionId: optionIDs,
          api_token: this.apiToken,
        };
        await axios
          .post("/api/custom/collection/updateElthBrands", data)
          .then((response) => {
            if (response.status == 200) {
              this.toast("Your store has been successfully updated.");
              this.$emit("gotoPage", { nav: "products", step: 3 });
            } else {
              this.toast("An error has occurred");
            }
          })
          .catch((error) => {
            this.toast("An error has occurred");
            console.log(error);
          });
      } else {
        this.toast(this.translateCustomTexts("Select at least one option."));
      }
      this.isLoadingSave = false;
    },

    toast(text) {
      this.$toasted.show(this.translateCustomText(text), {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
    openAddImageModal() {
      if (this.imagePlan.toLowerCase() != "free")
        this.$refs.UploadNewImageRef.click();
      else {
        this.toast("Update your plan to avail this functionality.");
      }
    },
  },
  mounted() {
    this.selectedBrandsIds = this.selectedBrands.map((brand) => brand.id);
  },
};
</script>
<style scoped>
* {
  --elthThemeColor: #503a3c;
}

.commonBtn.imgEditImage {
    height: auto !important;
    width:100% !important;
    aspect-ratio: 1/1 !important;
  }
.topSection .description {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.topSection .title {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.manageBtn {
  border-radius: 15px;
  background: #fff;
  color: #503a3c;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}

.editBtn:hover {
  background: #9c4629 !important;
  /* border: 1px solid ; */
  border-radius: 5px;
  color: #fff;
}
.commonBtn {
  height: 187.442px;
  width: 100%;

  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.scrollable-tbody::-webkit-scrollbar {
  width: 22px;
    background:#fff;
}

.scrollable-tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border: 1px solid var(--elthThemeColor);
  background-clip: padding-box;
    background:#fff;
}

.scrollable-tbody::-webkit-scrollbar-thumb {
  background-color: var(--elthThemeColor);
  outline: 1px solid var(--elthThemeColor);
  background-image: linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(white, white);
  background-position: 50% 38%, 50% 50%, 50% 65%;
  background-repeat: no-repeat;
  background-size: 50% 1px, 50% 1px, 50% 1px;
}

.scrollable-tbody::-webkit-scrollbar-button {
  border: 1px solid var(--elthThemeColor);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 1em;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.scrollable-tbody::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}

.scrollable-tbody::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.scrollable-tbody::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/* @media (max-width: 767px) {
  .commonBtn.imgEditImage {
    height: auto;
    aspect-ratio: 1/1;
  }
} */
.selected-image {
  box-shadow: none;
  outline: 4px solid;
}
@media (max-width: 767px) {
  .select-all {
    width: 319px !important;
  }
  .topSection .title{
    font-size: 22px;
    text-align: center;
  }
  .topSection .description{
    text-align: center;
  }
}
</style>
